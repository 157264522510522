import React, { Fragment, Suspense, useEffect, useState } from "react";
import Preloader from "../elements/Preloader";
import { useParams } from "react-router-dom";
import { CONSTANTS } from "../elements/constants";
const Breadcrumb = React.lazy(() => import("../components/Breadcrumb"));
const FooterBottomOne = React.lazy(() =>
  import("../components/FooterBottomOne")
);
const FooterOne = React.lazy(() => import("../components/FooterOne"));
const NavbarThree = React.lazy(() => import("../components/NavbarThree"));
const ServiceDetailsInner = React.lazy(() =>
  import("../components/ServiceDetailsInner")
);
const SearchPopup = React.lazy(() => import("../elements/SearchPopup"));
const ServiceDetails = () => {
  const { service } = useParams();
  const [title, setTitle] = useState([]);
  const [data, setData] = useState({});

  useEffect(() => {
    setTitle(CONSTANTS.services.filter((s) => s.url === service)[0].title);
    setData(CONSTANTS.serviceDetails.filter((s) => s.url === service)[0]);
  });

  // console.log(data);
  return (
    <>
      <Fragment>
        <Suspense fallback={<Preloader />}>
          {/* Search Popup */}
          <SearchPopup />

          {/* Navbar One */}
          <NavbarThree />

          {/* Breadcrumb */}
          <Breadcrumb title={title} />

          {/* Service Details Inner */}
          <ServiceDetailsInner data={data} />

          {/* Footer One */}
          <FooterOne />

          {/* Footer Bottom One */}
          <FooterBottomOne />
        </Suspense>{" "}
      </Fragment>
    </>
  );
};

export default ServiceDetails;
