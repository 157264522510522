import React from "react";
import { FaPhoneAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import { CONSTANTS } from "../elements/constants";

const ContactOne = () => {
  return (
    <>
      {/*contact-area start*/}
      <div>
        <img
          src="https://images.unsplash.com/photo-1448932223592-d1fc686e76ea?q=80&w=3269&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          style={{ width: "100%", maxHeight: "100%" }}
          alt="Transpro"
        />
        <div className="container">
          <div className="row justify-content-end">
            <div className="col-xl-6 col-lg-7">
              <div className="cta-inner-wrap">
                <div className="section-title style-white mb-0">
                  <h4 className="subtitle style-2">PARLONS-EN</h4>
                  <h2 className="title">BESOIN DE CONSEIL ? CONTACTEZ NOUS</h2>
                </div>
                {CONSTANTS.contact.map((contact) => (
                  <>
                    <p className="text-white">
                      {contact.title} : {contact.phone}
                      {/* {contact.email} */}
                    </p>
                    {/* <p className="text-white">{contact.phone}</p> */}
                  </>
                ))}
                <br />
                <Link className="btn btn-base" to="/contact">
                  Contactez nous
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*contact-area end*/}
    </>
  );
};

export default ContactOne;
