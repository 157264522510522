import React from "react";
import { CONSTANTS } from "../elements/constants";

const TransportServiceArea = () => {
  return (
    <>
      {/* Transport service area start */}
      <div className="transport-service-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title  text-center">
                <span className="subtitle">QUI SOMMES NOUS</span>
                <h2 className="title">NOS QUALITÉS ET PRIORITÉS</h2>
              </div>
            </div>
          </div>
          {CONSTANTS.about.bestPoints.map((point, index) =>
            index % 2 === 0 ? (
              <div className="row transport-service-single" key={index}>
                <div className="col-lg-6">
                  <div className="transport-service-image wow fadeInLeft">
                    <img
                      src={point.image}
                      className="img-fluid"
                      alt="transport-service images"
                    />
                    <div className="transport-service-item-hover">
                      <a href="/service">
                        <div className="transport-service-link">
                          <span className="fa fa-eye" />
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 offset-lg-1 d-flex">
                  <div className="transport-service-content wow fadeInRight">
                    <h3 className="title"> {point.title} </h3>
                    <p>{point.description}</p>
                    <div className="btn-wrapper animated fadeInUpBig text-left">
                      <a href="/service" className="boxed-btn blank">
                        Voir nos services
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="row transport-service-single" key={index}>
                <div className="col-lg-6 offset-lg-1 order-lg-2">
                  <div className="transport-service-image wow fadeInRight">
                    <img
                      src={point.image}
                      className="img-fluid"
                      alt="transport-service images"
                    />
                    <div className="transport-service-item-hover">
                      <a href="/service">
                        <div className="transport-service-link">
                          <span className="fa fa-eye" />
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 offset-lg-1 d-flex">
                  <div className="transport-service-content wow fadeInLeft">
                    <h3 className="title"> {point.title} </h3>
                    <p>{point.description}</p>
                    <div className="btn-wrapper animated fadeInUpBig text-left">
                      <a href="/service" className="boxed-btn blank">
                        Voir nos services
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            )
          )}
{/* 
          <div className="row justify-content-center">
            <div className="col-lg-2">
              <div className="btn-wrapper animated fadeInUpBig text-center">
                <a href="/service" className="boxed-btn btn-bounce">
                  View All
                </a>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      {/* Transport service area end */}
    </>
  );
};

export default TransportServiceArea;
