import React from "react";
import { CONSTANTS } from "../elements/constants";

const WhyChooseUsTwo = () => {
  return (
    <>
      {/*wcu-area start*/}
      <div
        className="wcu-area-2 pd-top-115"
        style={{ backgroundImage: "url(assets/img/wcu/bg-2.png)" }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="section-title style-white text-center">
                <h4 className="subtitle style-2">POURQUOI NOUS CHOISIR </h4>
                <h2 className="title">POURQUOI NOUS CHOISIR </h2>
                <p className="content">
                  Nous offrons des services fiables et de qualité, adaptés à vos
                  besoins. Grâce à notre expertise et à une équipe dédiée, nous
                  assurons des solutions efficaces et personnalisées, avec pour
                  priorité votre satisfaction.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4">
              {CONSTANTS.whyUs.map((value, index) => {
                return (
                  <>
                    {index < 3 && (
                      <div className="single-wcu-wrap">
                        <div className="icon">
                          <img src="assets/img/wcu/icon-1.png" alt="Transpro" />
                        </div>
                        <div className="details">
                          <h6>{value.subtitle}</h6>
                          <p>{value.text}</p>
                        </div>
                      </div>
                    )}
                  </>
                );
              })}
            </div>
            <div className="col-lg-4">
              {/* <div className="thumb text-center">
                <img src="assets/img/wcu/delivery-man.png" alt="Transpro" />
              </div> */}
            </div>
            <div className="col-lg-4">
              {CONSTANTS.whyUs.map((value, index) => {
                return (
                  <>
                    {index > 1 && (
                      <div className="single-wcu-wrap">
                        <div className="icon">
                          <img src="assets/img/wcu/icon-1.png" alt="Transpro" />
                        </div>
                        <div className="details">
                          <h6>{value.subtitle}</h6>
                          <p>{value.text}</p>
                        </div>
                      </div>
                    )}
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      {/*wcu-area end*/}
    </>
  );
};

export default WhyChooseUsTwo;
