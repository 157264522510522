export const CONSTANTS = {
  navbar: [
    {
      title: "Accueil",
      type: "",
      options: [],
      url: "/",
    },

    {
      title: "Notre société",
      type: "",
      options: [],
      url: "/about",
    },

    {
      title: "Nos services",
      type: "options",
      url: "/service",
      options: [
        {
          title: "Transport Routier",
          id: 1,

          url: "transport-routier",
          image:
            "https://plus.unsplash.com/premium_photo-1664695368767-c42483a0bda1?q=80&w=3272&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
          description:
            "Omnitrans assure vos transports internationaux par route, avec une gestion optimisée des délais et des coûts pour livrer vos marchandises en toute sécurité, partout dans le monde.",
        },
        {
          title: "Déménagement",
          url: "demenagement",
          id: 5,
          image:
            "https://images.unsplash.com/photo-1600725935160-f67ee4f6084a?q=80&w=3270&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
          description:
            "Omnitrans vous accompagne dans toutes les étapes de votre déménagement, avec un service clé en main pour un transfert sans souci.",
        },
        {
          title: "Affrètement",
          url: "affretement",
          id: 2,
          image:
            "https://images.unsplash.com/photo-1469289759076-d1484757abc3?q=80&w=3270&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
          description:
            "Omnitrans organise vos opérations d’affrètement avec rigueur, en sélectionnant les meilleurs partenaires pour acheminer vos marchandises rapidement et efficacement.",
        },
        // {
        //   title: "Distribution quotidienne",
        //   url: "distribution-quotidienne",
        //   id: 2,
        //   image:
        //     "https://images.unsplash.com/photo-1469289759076-d1484757abc3?q=80&w=3270&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        //   description:
        //     "Omnitrans organise vos opérations d’affrètement avec rigueur, en sélectionnant les meilleurs partenaires pour acheminer vos marchandises rapidement et efficacement.",
        // },
        {
          title: "Location de véhicule avec chauffeur",
          url: "location",
          id: 3,
          image:
            "https://plus.unsplash.com/premium_photo-1661290470322-a313098e7c2a?q=80&w=3270&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
          description:
            "Profitez de notre service de location de véhicules avec chauffeur, idéal pour des besoins ponctuels ou réguliers de transport professionnel en toute flexibilité.",
        },
      ],
    },

    {
      title: "Contactez nous ",
      type: "",
      options: [],
      url: "/contact",
    },
  ],
  services: [
    {
      title: "Transport Routier",
      id: 1,
      icon: 6,
      url: "transport-routier",
      image:
        "https://plus.unsplash.com/premium_photo-1664695368767-c42483a0bda1?q=80&w=3272&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      description:
        "Omnitrans assure vos transports internationaux par route, avec une gestion optimisée des délais et des coûts pour livrer vos marchandises en toute sécurité, partout dans le monde.",
    },
    {
      title: "Affrètement",
      id: 2,
      url: "affretement",
      icon: 3,
      image:
        "https://images.unsplash.com/photo-1469289759076-d1484757abc3?q=80&w=3270&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      description:
        "Omnitrans organise vos opérations d’affrètement avec rigueur, en sélectionnant les meilleurs partenaires pour acheminer vos marchandises rapidement et efficacement.",
    },

    {
      title: "Distribution quotidienne",
      id: 2,
      url: "distribution-quotidienne",
      icon: 3,
      image:
        "https://images.unsplash.com/photo-1469289759076-d1484757abc3?q=80&w=3270&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      description:
        "Omnitrans organise vos opérations d’affrètement avec rigueur, en sélectionnant les meilleurs partenaires pour acheminer vos marchandises rapidement et efficacement.",
    },
    {
      title: "Location de véhicule avec chauffeur",
      url: "location",
      id: 3,
      icon: 4,
      image:
        "https://plus.unsplash.com/premium_photo-1661290470322-a313098e7c2a?q=80&w=3270&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      description:
        "Profitez de notre service de location de véhicules avec chauffeur, idéal pour des besoins ponctuels ou réguliers de transport professionnel en toute flexibilité.",
    },
    {
      title: "Transport de mobilier avec montage possible",
      icon: 5,
      id: 6,
      url: "demenagement",
      image:
        "https://plus.unsplash.com/premium_photo-1680300960805-e76bb338d59e?q=80&w=3270&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      description:
        "Nous transportons et montons vos meubles, assurant une prestation complète pour le déménagement ou l’installation de vos mobiliers en toute sérénité.",
    },
    {
      title: "Déménagement",
      icon: 3,
      id: 5,
      url: "demenagement",
      image:
        "https://images.unsplash.com/photo-1600725935160-f67ee4f6084a?q=80&w=3270&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      description:
        "Une service complet adapté à vos besoins que vous soyez un particulier ou une entreprise",
    },
    {
      title: "Mise en garde-meuble",
      url: "demenagement",
      id: 7,
      icon: 3,
      image:
        "https://plus.unsplash.com/premium_photo-1682146353936-30c121b55131?q=80&w=3272&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      description:
        "Nous proposons un service de garde-meuble sécurisé pour entreposer vos biens en toute tranquillité, à court ou long terme.",
    },
    {
      title: "Mise en déchetterie",
      url: "demenagement",
      id: 8,
      icon: 3,
      image:
        "https://plus.unsplash.com/premium_photo-1663090499721-c10bd6b4ca37?q=80&w=3270&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      description:
        "Nous assurons la collecte et le transport de vos déchets vers les déchetteries, avec un service rapide et respectueux de l'environnement.",
    },
  ],
  whyUs: [
    {
      subtitle: "Expertise",
      text: "Notre expérience dans le secteur nous permet de gérer efficacement tous types de transports et de logistique, assurant des solutions adaptées à vos besoins spécifiques.",
    },
    {
      subtitle: "Fiabilité et professionnalisme",
      text: "Nous garantissons des services de transport sécurisés et ponctuels avec une attention particulière à la protection de vos marchandises.",
    },
    {
      subtitle: "Flexibilité",
      text: "Nos solutions sont conçues pour s’adapter à vos exigences, qu’il s’agisse de trajets locaux, internationaux ou de services spécialisés.",
    },
    {
      subtitle: "Service client dédié",
      text: "Notre équipe est disponible pour répondre à vos questions et assurer un suivi personnalisé, pour une expérience sans souci.",
    },
    {
      subtitle: "Proximité et confiance ",
      text: `Parceque nous sommes une entreprise familiale, nous comprenons l’importance
d’établire des relations de confiance. Nous sommes à l’écoute de nos clients et
disponibles pour répondre à toutes leurs préoccupations.`,
    },
    {
      subtitle: "Service sur-mesure",
      text: `Que vous soyez une entreprise cherchant une solution d’affrètement flexible ou un
particulier préparant un démenagement, nous offrons des solutions
personnalisées à chaque besoin et chaque budget.`,
    },
  ],
  contact: [
    {
      title: "Affretement",
      email: "affretement.omnitrans@gmail.com",
      phone: "07 80 74 53 54",
    },
    {
      title: "Tout autres services ",
      email: "exploitation.omnitrans@gmail.com",
      phone: "07 45 62 11 96",
    },
  ],
  contactFormPro: [
    {
      name: "lastname",
      type: "text",
      required: true,
      label: "Nom",
    },
    {
      name: "firstname",
      type: "text",
      required: true,
      label: "Prénom",
    },
    {
      name: "email",
      type: "email",
      required: true,
      label: "Votre Email",
    },
    {
      name: "phone",
      type: "number",
      required: true,
      label: "Telephone",
    },
    {
      name: "type_prospect",
      type: "select",
      required: true,
      label: "Vous etes : ",
      options: [
        // {
        //   title: "Sélectionner votre profil",
        //   value: false,
        // },
        {
          title: "Un professionnel / Une entreprise",
          value: "society",
        },
        {
          title: "Un particulier",
          value: "particulier",
        },
        {
          title: "Un transporteur",
          value: "transporteur",
        },
        {
          title: "Un candidat",
          value: "candidat",
        },
      ],
    },
    {
      name: "type_demande",
      type: "select",
      required: true,
      label: "Objet de votre demande ",
      options: [
        // {
        //   title: "Sélectionner votre thématique",
        //   value: false,
        // },
        {
          title: "Transport National",
          value: "transport_national",
        },
        {
          title: "Transport Européen",
          value: "transport_europeen",
        },
        {
          title: "Affrètement",
          value: "affretement",
        },
        {
          title: "Déménagement",
          value: "demenagment",
        },
        {
          title: "Location de véhicule avec chauffeur",
          value: "location",
        },
        {
          title: "Stockage",
          value: "stockage",
        },
        {
          title: "Réclamation",
          value: "reclamation",
        },
        {
          title: "Autre",
          value: "autre",
        },
      ],
    },
    {
      name: "society",
      type: "text",
      required: false,
      label: "Société",
    },
    {
      name: "adresse",
      type: "text",
      required: true,
      label: "Adresse",
    },
    {
      name: "cp",
      type: "number",
      required: true,
      label: "Code postal",
    },
    {
      name: "city",
      type: "text",
      required: true,
      label: "Ville",
    },

    {
      name: "message",
      type: "textarea",
      required: false,
      label: "Message",
    },
  ],
  type_demande: [
    {
      title: "Sélectionner votre thématique",
      value: false,
    },
    {
      title: "Transport National",
      value: "transport_national",
    },
    {
      title: "Transport Européen",
      value: "transport_europeen",
    },
    {
      title: "Affrètement",
      value: "affretement",
    },
    {
      title: "Déménagement",
      value: "demenagment",
    },
    {
      title: "Location de véhicule avec chauffeur",
      value: "location",
    },
    {
      title: "Stockage",
      value: "stockage",
    },
    {
      title: "Réclamation",
      value: "reclamation",
    },
    {
      title: "Autre",
      value: "autre",
    },
  ],
  about: {
    title: "OMNITRANS",
    description: `Depuis nos débuts, nous avons bâti notre réputation sur un engagement sans compromis envers la satisfaction de nos clients. Grâce à notre expertise, nous comprenons que chaque client a des besoins uniques. C’est pourquoi nous adaptons nos services à chaque projet dans les délais impartis.`,
    bestPoints: [
      {
        title: "Une équipe dédiée",
        image:
          "https://images.unsplash.com/photo-1521737852567-6949f3f9f2b5?w=700&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8N3x8VW5lJTIwJUMzJUE5cXVpcGUlMjBkJUMzJUE5ZGklQzMlQTllJTIwdHJhbnNwb3J0JTIwY2FyJTIwY2FtaW9uJTIwcG9pZHMlMjBsb3VyZHN8ZW58MHx8MHx8fDA%3D",
        description: `
        Un interlocuteur unique suivant votre besoin.
Nous faisons de chaque mission un projet personnel.
        `,
      },
      {
        title: "Fiabilité et professionalisme",
        image:
          "https://plus.unsplash.com/premium_photo-1661963219843-f1a50a6cfcd3?w=700&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8RmlhYmlsaXQlQzMlQTklMjBldCUyMHByb2Zlc3Npb25hbGlzbWUlMjB0cmFuc3BvcnQlMjBjYXIlMjBjYW1pb24lMjBwb2lkcyUyMGxvdXJkc3xlbnwwfHwwfHx8MA%3D%3D",
        description: `
        Avec une flotte de véhicule moderne, une équipe de chauffeurs professionnels
et un interlocuteur unique pour vos affrètement, nous garantissons que vos
marchandises arrivent en toute sécurité, à temps et dans le respect des normes les
plus strictes.
        `,
      },
      {
        title: "Service sur-mesure",
        image:
          "https://images.unsplash.com/photo-1469289759076-d1484757abc3?q=80&w=1740&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",

        description: `
        Que vous soyez une entreprise cherchant une solution d’affrètement flexible ou un
particulier préparant un démenagement, nous offrons des solutions
personnalisées à chaque besoin et chaque budget.
        `,
      },
      {
        title: "Proximité et confiance",
        image:
          "https://plus.unsplash.com/premium_photo-1681487855134-d6c0434f91f8?w=700&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjV8fFByb3hpbWl0JUMzJUE5JTIwZXQlMjBjb25maWFuY2UlMjB0cmFuc3BvcnQlMjBjYXIlMjBjYW1pb24lMjBwb2lkcyUyMGxvdXJkc3xlbnwwfHwwfHx8MA%3D%3D",
        description: `Parceque nous sommes une entreprise familiale, nous comprenons l’importance
        d’établire des relations de confiance. Nous sommes à l’écoute de nos clients et
        disponibles pour répondre à toutes leurs préoccupations.`,
      },
    ],
  },

  serviceDetails: [
    {
      id: 2,
      url: "affretement",
      image:
        "https://images.unsplash.com/photo-1469289759076-d1484757abc3?q=80&w=3270&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",

      title: "Affrètement",
      icon: 3,
      description: [
        {
          title: "Délais Rapides",
          context:
            "Nous nous engageons à respecter des délais de livraison rapides et efficaces, adaptés aux contraintes de vos projets. Que ce soit pour des livraisons urgentes ou planifiées, notre équipe met tout en œuvre pour réduire les temps de transit et garantir la ponctualité des livraisons, en local comme à l’international.",
        },
        {
          title: "Coûts Maîtrisés",
          context:
            "Grâce à notre expertise et notre optimisation des processus de transport, nous vous offrons des services à des coûts maîtrisés, sans compromis sur la qualité. Nous vous proposons des solutions transparentes et compétitives qui respectent votre budget tout en répondant à vos besoins en matière de transport et de logistique.",
        },
        {
          title: "Solution sur-Mesure",
          context:
            "Chaque projet est unique, et nous mettons un point d'honneur à vous offrir des solutions de transport personnalisées, en fonction de vos besoins spécifiques. Que ce soit pour des volumes particuliers, des délais spécifiques ou des marchandises fragiles, nous concevons une offre sur-mesure pour répondre à vos exigences.",
        },
        {
          title: "Nationale et Internationale",
          context:
            "Nous couvrons aussi bien le territoire national que les destinations internationales. Grâce à notre réseau étendu et nos partenaires fiables, nous vous assurons un transport fluide de vos marchandises, que ce soit pour des livraisons en France ou à l’étranger, avec une gestion complète des formalités douanières si nécessaire.",
        },
      ],

      bestPoints: [
        "Délais rapides",
        "Coûts maîtrisés.",
        "solution sur-mesure",
        "nationale et internationale.",
      ],
      process: {
        title: "Comment nous procèdons ?",
        description: "",
      },
    },

    {
      id: 2,
      url: "distribution-quotidienne",
      image:
        "https://images.unsplash.com/photo-1469289759076-d1484757abc3?q=80&w=3270&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",

      title: "Distribution quotidienne",
      icon: 3,
      description: [
        {
          title: "Délais Rapides",
          context:
            "Nous nous engageons à respecter des délais de livraison rapides et efficaces, adaptés aux contraintes de vos projets. Que ce soit pour des livraisons urgentes ou planifiées, notre équipe met tout en œuvre pour réduire les temps de transit et garantir la ponctualité des livraisons, en local comme à l’international.",
        },
        {
          title: "Coûts Maîtrisés",
          context:
            "Grâce à notre expertise et notre optimisation des processus de transport, nous vous offrons des services à des coûts maîtrisés, sans compromis sur la qualité. Nous vous proposons des solutions transparentes et compétitives qui respectent votre budget tout en répondant à vos besoins en matière de transport et de logistique.",
        },
        {
          title: "Solution sur-Mesure",
          context:
            "Chaque projet est unique, et nous mettons un point d'honneur à vous offrir des solutions de transport personnalisées, en fonction de vos besoins spécifiques. Que ce soit pour des volumes particuliers, des délais spécifiques ou des marchandises fragiles, nous concevons une offre sur-mesure pour répondre à vos exigences.",
        },
        {
          title: "Nationale et Internationale",
          context:
            "Nous couvrons aussi bien le territoire national que les destinations internationales. Grâce à notre réseau étendu et nos partenaires fiables, nous vous assurons un transport fluide de vos marchandises, que ce soit pour des livraisons en France ou à l’étranger, avec une gestion complète des formalités douanières si nécessaire.",
        },
      ],

      bestPoints: [
        "Délais rapides",
        "Coûts maîtrisés.",
        "solution sur-mesure",
        "nationale et internationale.",
      ],
      process: {
        title: "Comment nous procèdons ?",
        description: "",
      },
    },

    {
      id: 5,
      url: "demenagement",
      title: "Déménagement",
      icon: 3,
      image:
        "https://images.unsplash.com/photo-1600725935160-f67ee4f6084a?q=80&w=3270&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",

      description: [
        {
          title: "Déménagement de Particuliers",
          context:
            "Que vous déménagiez dans une nouvelle maison ou un nouvel appartement, nous prenons en charge tous les aspects du déménagement. De l’emballage de vos effets personnels à la mise en place de votre nouveau logement, nous gérons chaque étape avec soin. Nous assurons la protection de votre mobilier grâce à des emballages spécialisés, et mettons à disposition une équipe qualifiée pour le montage et démontage de vos meubles au besoin.",
        },
        {
          title: "Déménagement d'Entreprises",
          context:
            "Nous proposons également un service de déménagement dédié aux entreprises. Nous organisons le transfert de bureaux, d’équipements et de documents tout en garantissant une interruption minimale de votre activité.",
        },
        {
          title: "Mise en Garde-Meuble",
          context:
            "Si vous avez besoin de stocker temporairement vos biens lors d’un déménagement, nous mettons à votre disposition un service de garde-meuble sécurisé. Vous pouvez entreposer vos meubles et effets personnels dans un espace de stockage adapté. Ce service est idéal si votre nouveau logement ou local n’est pas encore prêt, ou si vous avez besoin de stocker des meubles en surplus.",
        },
        {
          title: "Mise en Déchetterie",
          context:
            "Dans le cadre de votre déménagement, nous proposons un service de mise en déchetterie pour vous débarrasser des objets ou meubles que vous ne souhaitez pas conserver. Nous nous occupons de tout : transport, tri et dépose des déchets dans des centres agréés, dans le respect des règlementations en vigueur pour le recyclage et la gestion des déchets.",
        },
      ],
      bestPoints: [
        "Déménagement de particulier",
        "Déménagement d’entreprise",
        "Mise en garde meuble",
        "Mise en déchetterie",
      ],
      process: {
        title: "",
        url: "",
        description: "",
      },
    },

    {
      id: 3,
      url: "location",
      image:
        "https://plus.unsplash.com/premium_photo-1661290470322-a313098e7c2a?q=80&w=3270&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",

      title: "Location de véhicule avec conducteur",
      icon: 6,
      description: [
        {
          title: "Location de Véhicule Moderne",
          context:
            "Nous mettons à votre disposition une flotte de véhicules modernes, parfaitement adaptés à tous types de besoins de transport. Nos véhicules sont équipés des dernières technologies pour garantir la sécurité, l'efficacité et la fiabilité. Que vous ayez besoin d'un camion pour un déménagement, de véhicules pour des livraisons régulières ou de transport spécifique, notre flotte saura répondre à vos attentes.",
        },
        {
          title: "Conducteur Professionnel",
          context:
            "Nos conducteurs professionnels sont formés pour assurer des trajets sécurisés, ponctuels et en conformité avec les réglementations routières. Grâce à leur connaissance approfondie du réseau routier local et national, ils sont capables d’optimiser chaque livraison et transport, tout en garantissant une conduite souple et respectueuse de vos marchandises.",
        },
        {
          title:
            "Flexible et Sécuritaire pour vos Projets Nécessitant des Déplacements de Marchandises",
          context:
            "Que vous ayez un besoin ponctuel ou récurrent de transport, nous vous offrons des solutions flexibles et sécurisées adaptées à vos projets. Nous assurons le déplacement de vos marchandises avec une grande attention portée à la sécurité, tout en vous offrant la possibilité d’ajuster nos services en fonction de vos contraintes de temps et de volume. Nos solutions de transport vous garantissent un service fiable et modulable en fonction de vos besoins spécifiques.",
        },
      ],
      bestPoints: [
        "Location de véhicule moderne",
        "Conducteur professionnel",
        "Flexible et sécuritaire pour vos projets nécessitant des déplacements de marchandises",
      ],
      process: {
        title: "",
        url: "",
        description: "",
      },
    },

    {
      id: 1,
      url: "transport-routier",
      title: "Transport routier",
      icon: 6,
      image:
        "https://plus.unsplash.com/premium_photo-1663950995673-f4916a77ca6d?q=80&w=3175&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      description: [
        {
          title: "Distribution Quotidienne",
          context:
            "Nous assurons la distribution quotidienne de vos marchandises grâce à notre équipe professionnelle et notre flotte moderne adaptée à tout type de charge. Notre expertise en gestion de tournées assure une couverture géographique large en respectant les délais impartis. Ce service est idéal pour les entreprises ayant des besoins réguliers de distribution locale ou nationale pour des clients professionnels ou particuliers.",
        },
        {
          title: "Transports Nationaux et Européens",
          context:
            "Pour répondre à vos besoins de transports ponctuels, nous offrons une solution flexible à travers la France et toute l’Europe. Notre équipe gère l’intégralité du processus, de la planification à la livraison finale, en passant par la gestion des formalités douanières si nécessaire. Ce service s’adapte aux urgences, aux variations saisonnières ou à des projets spécifiques.",
        },
        {
          title: "Livraison de votre Mobilier à Domicile",
          context:
            "Un service dédié aux livraisons de meubles avec prise de rendez-vous, une gestion soignée du mobilier et une installation à domicile si nécessaire. Nous proposons un service de livraison spécialisé pour le mobilier, destiné aux particuliers comme aux professionnels. Ce service inclut non seulement la livraison à domicile, mais aussi l’installation et la gestion de la logistique liée au transport de meubles volumineux et fragiles.",
        },
      ],
      bestPoints: [
        "Distribution quotidienne",
        "Transport nationaux et Européens ",
        "Livraison de votre mobilier à domicile ",
      ],
      process: {
        title: "",
        url: "",
        description: "",
      },
    },

    // Ajout de l'objet pour la mise en garde meuble
    {
      id: 7,
      url: "garde-meuble",
      title: "Mise en garde meuble",
      image:
        "https://images.unsplash.com/photo-1604079625811-5dd91ff8a452?q=80&w=3270&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      description: [
        {
          title: "Stockage Sécurisé",
          context:
            "Nous offrons des solutions de stockage sécurisé pour vos biens et marchandises. Nos entrepôts sont équipés de systèmes de surveillance avancés et de mesures de sécurité renforcées, garantissant une protection optimale de vos biens tout au long de leur entreposage.",
        },
        {
          title: "Flexible et Adapté",
          context:
            "Nos solutions de stockage et de logistique sont flexibles et peuvent être adaptées à vos besoins spécifiques. Que vous ayez besoin de stockage temporaire ou d’un espace plus vaste pour une période prolongée, nous ajustons nos services pour répondre à vos exigences.",
        },
        {
          title: "Solutions de Court et Long Terme",
          context:
            "Nous proposons des options de stockage à court et à long terme, selon vos besoins. Que vous ayez besoin d’un espace de stockage temporaire lors d'un projet spécifique ou d’une solution permanente pour vos marchandises, nos services s’adaptent à la durée nécessaire tout en garantissant la sécurité et la flexibilité.",
        },
      ],
      bestPoints: [
        "Stockage sécurisé",
        "Flexible et adapté",
        "Solutions de court et long terme",
      ],
      process: {
        title: "",
        url: "",
        description: "",
      },
    },

    // Ajout de l'objet pour la mise en déchetterie
    {
      id: 8,
      url: "mise-en-dechetterie",
      title: "Mise en déchetterie",
      image:
        "https://images.unsplash.com/photo-1585508889193-9d4d9a191edb?q=80&w=3270&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      description: [
        "Dans le cadre de votre déménagement, nous proposons un service de mise en déchetterie pour vous débarrasser des objets ou meubles inutiles. Nous nous chargeons du transport, tri et dépose dans des centres agréés.",
      ],
      bestPoints: [
        "Gestion complète des déchets",
        "Transport sécurisé",
        "Respect des règlementations",
      ],
      process: {
        title: "",
        url: "",
        description: "",
      },
    },

    // {
    //   title: "",
    //   url: "",
    //   description: "",
    //   bestPoints: "",
    //   process: {
    //     title: "",
    //     url: "",
    //     description: "",
    //   },
    // },
  ],
};
