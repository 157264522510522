import React, { Fragment, Suspense } from "react";
import Preloader from "../elements/Preloader";
import TransportServiceArea from "../components/TransportServiceArea";
import ContactOne from "../components/ContactOne";
const AboutOne = React.lazy(() => import("../components/AboutOne"));
const Breadcrumb = React.lazy(() => import("../components/Breadcrumb"));
const CounterOne = React.lazy(() => import("../components/CounterOne"));
const FooterBottomOne = React.lazy(() =>
  import("../components/FooterBottomOne")
);
const FooterOne = React.lazy(() => import("../components/FooterOne"));
const NavbarThree = React.lazy(() => import("../components/NavbarThree"));
const PartnerOne = React.lazy(() => import("../components/PartnerOne"));
const SkillOne = React.lazy(() => import("../components/SkillOne"));
const TeamOne = React.lazy(() => import("../components/TeamOne"));
const TestimonialTwo = React.lazy(() => import("../components/TestimonialTwo"));
const VideoAreaOne = React.lazy(() => import("../components/VideoAreaOne"));
const SearchPopup = React.lazy(() => import("../elements/SearchPopup"));
const About = () => {
  return (
    <>
      <Fragment>
        <Suspense fallback={<Preloader />}>
          {/* Search Popup */}
          <SearchPopup />

          {/* Navbar One */}
          <NavbarThree />

          {/* Breadcrumb */}
          <Breadcrumb title={"NOTRE SOCIETE"} />

          <div className="container pt-5 mt-5">
            <div className="section-title text-center">
              <p>
                Chez
                <strong> Omnitrans,</strong>
                nous croyons que le transport est bien plus qu’une simple
                livraison d’un point À à un point B.
                <br />
                Fondée et gérée par
                <strong> deux frères passionnés,</strong>
                notre entreprise familiale s’engage à offrir des
                <strong> services de transports </strong>
                qui reposent sur des valeurs solides : <br/>
                <strong> Fiabilité, Proximité et efficacité.</strong>
              </p>
            </div>
          </div>

          {/* About One */}
          <div className="pd-top-120 pd-bottom-120">
            <AboutOne />
          </div>

          <TransportServiceArea />

          {/* Counter One */}

          {/* <div className='fact-area' style={{ background: "#f9f9f9" }}>
            <CounterOne />
          </div> */}

          {/* Skill One */}
          {/* <SkillOne /> */}

          {/* Video Area One */}
          {/* <VideoAreaOne /> */}

          {/* Team One */}
          {/* <div className='pd-bottom-80'>
            <TeamOne />
          </div> */}

          {/* Testimonial Two */}
          {/* <TestimonialTwo /> */}

          {/* Partner One */}
          {/* <PartnerOne /> */}

          {/* Footer One */}
          <div
            className="call-to-contact-area pd-top-140  mt-0"
            style={{ background: "#F9F9F9" }}
          >
            <ContactOne />
          </div>
          <FooterOne />

          {/* Footer Bottom One */}
          <FooterBottomOne />
        </Suspense>
      </Fragment>
    </>
  );
};

export default About;
